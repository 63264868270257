<script>
    export let title = "";
    export let desc = "";
    export let extra = "";
    export let index = true;
    $: document.title = title;
</script>

<svelte:head>
    {#if desc}<meta name="description" content={desc}>{/if}
    {#if index}
        <meta name="robots" content="index,follow">
    {:else}
        <meta name="robots" content="noindex,nofollow">
    {/if}
    {@html extra || ""}
</svelte:head>