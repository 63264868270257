<script context="module">
    // Tries to detect a supported language in the user's window.language(s) list
    // supported: array of supported languages
    // prefered: which language to serve if supported lang. not found, default to supported[0]
    export function langDetect(supported = ["en", "sk"], prefered = supported[0]) {
        var detected;
        var final;
        if (
            ( window.navigator.language && (detected = supported.filter(lang => window.navigator.language.substr(0,2) === lang)) && detected.length ) ||
            ( window.navigator.languages && window.navigator.languages.length && (detected = window.navigator.languages.filter(lang => supported.includes(lang.substr(0,2)))) && detected.length )
        ) {
            final = detected[0].substr(0,2);
        } else {
            final = prefered;
        }
        return final;
    }
    // Detects the language from first part of slug if possible, otherwise using function above (langDetect)
    // Returns an array [supportedLanguage, slugWithoutLanguage, wasNotInURL (bool)]
    export function langSlugDetect(supportedLanguages,fullSlug) {
        let extracted = ["","",true]; // [lang, slug w/o lang]
        let parts = fullSlug.split("/").filter(sub=>sub!="");
        if (supportedLanguages.includes(parts[0])) {
            extracted[0] = parts[0];
            extracted[1] = "/" + parts.slice(1).join("/");
            extracted[2] = false;
        } else {
            extracted[0] = langDetect(supportedLanguages);
            extracted[1] = "/" + parts.join("/");
            extracted[2] = true;
        }
        return extracted;
    }
    export function removeLangFromSlug(supportedLanguages,fullSlug) {
        let parts = fullSlug.split("/").filter(sub=>sub!="");
        if (supportedLanguages.includes(parts[0])) {
            return "/" + parts.slice(1).join("/");
        } else {
            return fullSlug;
        }
    }
    export function getLangFromSlug(supportedLanguages,fullSlug) {
        let parts = fullSlug.split("/").filter(sub=>sub!="");
        if (supportedLanguages.includes(parts[0])) {
            return parts[0];
        } else {
            return "";
        }
    }
</script>