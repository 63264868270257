<script context="module">
    let slug;

    // Dynamic routes
    export function route(slug,newslug,preventNewState=0) {
        if (slug == newslug) {return slug;} // Don't route to same page, prevents history congestion
        slug = newslug;
        if (!preventNewState) window.history.pushState(null,slug,slug);
        if (preventNewState === 2) window.history.replaceState(null,slug,slug);
        window.scrollTo(0,0);
        return slug;
    }
</script>