<script context="module">
    // Load JS scripts with callback
    // This allows for them to be loaded after each other in case of dependencies
    export function loadScript( url, callback = function(){} ) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = function() { callback(); };
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild( script );
    }
</script>