<script>
    import { onDestroy } from "svelte";
    import { lang } from "../routes/LanguageStore.svelte";
    import { acnt } from "../routes/AccentStore.svelte";

    export let dropdown = true;
    let current;
    let supported;
    let langUnsub = lang.subscribe(l => {
        current = l.current;
        supported = l.supported;
    });

    let accent;
    let acntUnsub = acnt.subscribe(a => accent = a.current);

    $: lang.change(current);
    onDestroy(()=>{
        langUnsub();
        acntUnsub();
    });

    function handleNavClick(e) {
        e.preventDefault();
        current = e.target.getAttribute("lang");
    }
</script>

{#if supported && supported.length}
{#if dropdown}
<select class="language" bind:value={current} style={"color: " + accent + "; background-image: url(\"data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path fill='" + accent + "' d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>\");"}>
    {#each supported as lang}
        <option value={lang} selected={lang === current} aria-label={"Language " + lang.toUpperCase()}>{lang}</option>
    {/each}
</select>
{:else}
<nav class="language">
    {#each supported as lang}
        <a href={"/" + lang + "/"} class={current === lang && "current"} on:click={handleNavClick} lang={lang} style={"color: " + accent + ";"}>{lang}</a>
    {/each}
</nav>
{/if}
{/if}

<style lang="scss" global>
    select.language {
        height: 20px;
        padding: 0 15px 0 2px;
        margin: 0;
        font-size: 16px;
        // color: gray;
        border: 1px solid rgba(128, 128, 128, 0.5);
        background: transparent;
        text-transform: uppercase;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        &::-ms-expand { display: none; }
        // #0084ff === rgb(0,132,255);
        background: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path fill='rgb(0,132,255)' d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>");
        background-repeat: no-repeat;
        background-size: 12px 15px;
        background-position-x: calc(100% - 2px);
        background-position-y: 2px;
        font-weight: bold;
    }
    nav.language {
        font-size: 16px;
        text-transform: uppercase;
        a.current {
            font-weight: bold;
        }
    }
</style>