<script>
    export let fullscreen = false;
</script>

{#if fullscreen}
<section class="loading fullscreen">
    <img class="spinner" src="/img/loading.svg" alt="Loading / Načítavanie">
</section>
{:else}
<img class="spinner" src="/img/loading.svg" alt="Loading / Načítavanie">
{/if}