<script>
    import { onMount } from "svelte";
    export let classes = [];

    // Add / Remove body classes on mount
    onMount(()=>{
        classes.forEach(cls => {
            document.body.classList.add(cls);
        })
        return ()=>{
            classes.forEach(cls => {
                document.body.classList.remove(cls);
            })
        }
    })
</script>