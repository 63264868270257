<script>
    import { onMount } from "svelte";
    import { handleOffscreenClass } from "../scripts/isOffScreen.svelte";
    import Nav from "./parts/Nav.svelte";

    export let logo = "";
    export let logo_fa = "";
    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    export let text = "";
    export let nav = [];

    // function handleOffscreenClass(elm) {
    //     isOffScreen(elm,(e)=>{
    //         e.classList.add("offscreen")
    //     },(e)=>{
    //         e.classList.remove("offscreen")
    //     });
    // }

    let section;
    onMount(()=>{
        handleOffscreenClass(section,32);
    })
</script>

<svelte:window
    on:resize={handleOffscreenClass(section,32)}
    on:scroll={handleOffscreenClass(section,32)}
/>

<section class={"bar " + scheme} bind:this={section}>
    {#if logo && logo.path}
        <img 
            src={'https://www.saintdust.com' + logo.path} 
            alt={logo.meta && logo.meta.title ? logo.meta.title : "Logo"}
        >
    {:else if logo_fa}
        <big class="icon"><i class={logo_fa}></i></big>
    {/if}
    {#if text}<span>{@html text}</span>{/if}
    {#if nav}
        <!-- <nav>
        {#each nav as link}
            <a class={(link.button) ? "button" : ""} href={(link.href) ? link.href : "#"} lity={(nav.lity) ? nav.lity : false}>{@html link.name}</a>
        {/each}
        </nav> -->
        <Nav nav={nav} />
    {/if}
</section>

<style lang="scss" global>
    section.bar {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 60px;
        padding: 10px 15px;
        background-color: #f4f4f4;
        border: 1px solid lightgray;
        border-left: none;
        border-right: none;
        box-sizing: border-box;
        border-left: none;
        border-right: none;
        color: black;
        > *:not(:last-child) {
            margin-right: 3em;
        }
        &.dark {
            color: white;
            background: black;
            border: 1px solid rgb(66, 66, 66);
            border-left: none;
            border-right: none;
        }
        img {
            max-height: 40px;
        }
        span {
            animation-name: scalein;
            animation-duration: 0.75s;
        }
        big.icon {
            font-size: 48px;
            margin-right: 1em !important;
        }
        &.offscreen span {
            // animation-name: none;
            animation-name: fadeout;
            animation-duration: 0.75s;
            animation-fill-mode: forwards;
        }
        @media (max-width: 1200px) {
            flex-flow: column;
            justify-content: center;
            align-items: center;
            padding: 1em;
            // width: calc(100vw - 3em);
            // margin: 1em;
            // border-left: 1px solid lightgray;
            // border-right: 1px solid lightgray;
            > *:not(:last-child) {
                margin-right: 0;
                margin-bottom: 1em;
            }
            big.icon {
                margin-right: 0 !important;
                margin-bottom: 0.3em !important;
            }
        }
    }
    body.theme-goldshow .bar.light {
        background-color: #262626;
        border-color: rgb(66, 66, 66);
        color: white;
    }
</style>