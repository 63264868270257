<script>
    import { loadScript } from "../scripts/JSLoaderModule.svelte";

    // Global external JS

    // FontAwesome
    loadScript("/js/fontawesome.min.js",()=>{
        // Addons - embedded directly into fontawesome.min.js because stupid issues
        // loadScript("/js/fontawesome-custom.js");
    });

    // nextSection() function for easy inpage navigation
    // Meant to be used in CockpitCMS links
    loadScript("/js/nextSection.js?v=5");
</script>