<script context="module">
    // Convert any YouTube link to an embedded link
    export function YTurlToEmbed(url,autoplay = false){
        var id;
        if (url) { 
            try {
                // id = url.match(/.*?youtube.*?watch\?v=(.*)|.*?youtu\.be\/(.*)|.*?youtube.com\/embed\/(.*)/).filter(matches => {return matches})[1];
                id = url.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+).*/).filter(matches => {return matches})[1];
            } catch {}
        }
        return id ? "https://www.youtube-nocookie.com/embed/" + id + (autoplay ? "?autoplay=1" : "") : "";
    }
</script>