<script context="module">
    import { writable } from 'svelte/store';
    // Create a writable store with no public (or even private) set method
    export const modal = (()=>{
        // Init
        const { subscribe, update } = writable({
            href: "",
            active: false
        });
        // Public functions that allow modal manipulation
        return {
            subscribe,
            activate: (_href) => update(w => {
                return {
                    href: _href,
                    active: true
                };
            }),
            deactivate: () => update(w => {w.active = false; return w;})
        };
    })();
</script>

<script>
    import { onDestroy } from 'svelte';
    import { YTurlToEmbed } from "../scripts/YTurlToEmbed.svelte";

    // Modal store management
    let href = "";
    let active = false;

    const unsub = modal.subscribe(w => {
        href = w.href;
        active = w.active;
    });

    onDestroy(unsub);

    // Disable document overflow while active
    function bodyClass(active) {
        var cl = document.documentElement.classList;
        active ? cl.add("modal-active") : cl.remove("modal-active");
    }
    $: bodyClass(active);

    // YouTube link-to-embed functionality
    let adjustedHref;
    let isImage = false;
    $: adjustedHref = ((embedlink) => (embedlink ? embedlink : href))(YTurlToEmbed(href,true));
    $: isImage = ((url) => {
        let splitUp = url.split(".");
        return ["jpg","png","svg"].includes(splitUp[splitUp.length - 1]) ? true : false;
    })(href);

</script>

{#if active}
<div id="modal">
    {#if isImage}
        <!-- svelte-ignore a11y-img-redundant-alt -->
        <img src={href.includes('://') ? href : 'https://www.saintdust.com' + href} alt="Modálny Obrázok / Modal Image">
    {:else}
        <iframe title="modal" src={adjustedHref} frameborder="0"></iframe>
    {/if}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="bg-close" on:click={modal.deactivate}>
        <button on:click={modal.deactivate}><i class="fa fa-times"></i></button>
    </div>
</div>
{/if}

<style lang="scss" global>
    html.modal-active {
        overflow: hidden;
        body {overflow: hidden;}
    }
    #modal {
        display: flex;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 1000;
        flex-flow: column;
        box-sizing: border-box;
        padding: 2em;
        justify-content: center;
        align-items: center;
        iframe, img {
            position: relative;
            width: 70vw;
            height: calc(70vw / 16 * 9);
            z-index: 1005;
            // background: white;
        }
        img {
            object-fit: contain;
        }
        .bg-close {
            position: absolute;
            background: rgba(94, 94, 94, 0.712);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px); // firefox unsupported rn unless about:config flag https://platform-status.mozilla.org/#css-backdrop-filter
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            button {
                position: absolute;
                right: 0;
                top: 0;
                font-size:36px;
            }
        }
    }
</style>