<script context="module">
    export function isOffScreen(elm,callbackYes=()=>{},callbackNo=()=>{},intolerance=5) {
        if (elm) {
            let { top, bottom, height } = elm.getBoundingClientRect();
            // if (!(bottom - window.innerHeight > height/3 || top < -height/3)) {
            if (window.innerHeight - top > window.innerHeight/intolerance && bottom > window.innerHeight/intolerance) {
                callbackNo(elm);
            } else {
                callbackYes(elm);
            }
        }
    }
    export function handleOffscreenClass(elm,intolerance=5) {
        isOffScreen(elm,(e)=>{e.classList.add("offscreen")},(e)=>{e.classList.remove("offscreen")},intolerance);
    }
</script>