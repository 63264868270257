<script>
    import { startSlideshow, animate } from "../scripts/Slideshow.svelte";
    import { onMount } from "svelte";

    export let bubbles = [];
    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    
    function generateFaStars(count) {
        let html = "";
        for(let i = 0; i < count; i++) {
            html += '<i class="fa fa-star"></i>';
        }
        for(let i = count; i < 5; i++) {
            html += '<i class="far fa-star"></i>';
        }
        return html;
    }


    let slides;
    let startTimer;
    let isPaused = false;
    let stopSlideshow = ()=>{};
    let slideshowGetCurrent = ()=>{return undefined};

    function moveSlide(previous=false) {
        if (slides && slides.children) {
            var current = slideshowGetCurrent();
            stopSlideshowAndTimer();
            animate(slides.children,current,previous,true,(next)=>{
                slideshowGetCurrent = ()=>{return next;}
            })
        }
    }

    function stopSlideshowAndTimer() {
        stopSlideshow();
        isPaused = true;
        if (startTimer) clearTimeout(startTimer);
    }
    
    function continueSlideshowTimer() {
        isPaused = false;
        startTimer = setTimeout(()=>{
            stopSlideshow();
            if (slides && slides.children) [slideshowGetCurrent, stopSlideshow] = startSlideshow(slides.children,slideshowGetCurrent());
        }, 2500)
    }

    onMount(()=>{
        if (slides && slides.children) [slideshowGetCurrent, stopSlideshow] = startSlideshow(slides.children);
        return ()=> {stopSlideshow();}
    })
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<section class={"bubbles " + scheme} on:mouseover={stopSlideshowAndTimer} on:mouseout={continueSlideshowTimer}>
    {#if bubbles && bubbles.length}
        <div class="slides" bind:this={slides}>
        {#each bubbles as slide}
        {#if slide.value}
            <div class="slide hidden">
                {#if slide.value.text}<p>{@html slide.value.text}</p>{/if}
                <!-- <div class="bottom"> -->
                    {#if slide.value.author}<div class="author">~ {slide.value.author}</div>{/if}
                    <!-- {#if slide.value.stars}<div class="stars">{@html generateFaStars(slide.value.stars)}</div>{/if} -->
                <!-- </div> -->
            </div>
        {/if}
        {/each}
        </div>
        {#if isPaused}<div class="paused" aria-label="Slideshow is currently paused"><i class="fa fa-pause"></i></div>{/if}
        <button class="navigate prev" on:click={()=>{moveSlide(true)}} aria-label="Previous slide"><i class="fa fa-angle-double-left"></i></button>
        <button class="navigate next" on:click={()=>{moveSlide()}} aria-label="Next slide"><i class="fa fa-angle-double-right"></i></button>
    {/if}
</section>

<style lang="scss" global>
    section.bubbles {
        height: 250px;
        width: 100%;
        display: flex;
        position: relative;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: white;
        color: black;
        .slide {
            position: relative;
            border: 1px solid lightgray;
            padding: 25px 40px;
            min-width: 200px;
            max-width: 900px;
            .bottom {
                display: flex;
                min-height: 30px;
                justify-content: flex-end;
            }
            .author {
                text-align: right;
                font-weight: bold;
                font-style: italic;
            }
            // .stars {
            //     position: absolute;
            //     font-size: 36px;
            //     left: -40px;
            //     bottom: 10px;
            //     background: white;
            //     color: goldenrod;
            // }
        }
        button.navigate {
            position: absolute;
            background: white;
            border: 1px solid lightgray;
            color: #0083FF; // color-link
            margin: 20px;
            width: 50px;
            height: 50px;
            padding: 0;
            align-items: center;
            border-radius: 50%;
            &.prev {left: calc(50vw - 600px);}
            &.next {right: calc(50vw - 600px);}
            @media (max-width: 1200px) {
                &.prev {left: 0px;}
                &.next {left: auto; right: 0px;}
            }
            @media (max-width: 600px) {
                width: 40px;
                height: 40px;
                margin: 5px;
            }
        }
        &.dark {
            color: white;
            background: black;
            .slide {
                border: 1px solid rgb(66, 66, 66);
            }
            .stars {
                background: black;
            }
            button.navigate {
                border: 1px solid rgb(66, 66, 66);
                background: #262626;
            }
        }
        
        div.paused {
            position: absolute;
            bottom: 8px;
            left: 0;
            right: 0;
            opacity: 0.5;
            display: flex;
            justify-content: center;
        }
        .hidden { display: none; }
        .animate-in {
            @keyframes slidein {
                from { transform: translateX(-100%); opacity: 0;}
                to { transform: translateX(0%); opacity: 1;}
            }
            animation-name: slidein;
            animation-duration: 1s;
        }
        .animate-out {
            @keyframes slideout {
                from { transform: translateX(0%); opacity: 1;}
                to { transform: translateX(100%); opacity: 0;}
            }
            animation-name: slideout;
            animation-duration: 1s;
        }
    }
</style>