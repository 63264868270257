<script>
    import { onMount } from 'svelte';
    import { handleOffscreenClass } from '../scripts/isOffScreen.svelte';

    handleOffscreenClass

    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    export let bg_img = "";
    export let ranks = [];

    let section;
    onMount(()=>{
        handleOffscreenClass(section);
    })
</script>

<svelte:window 
    on:scroll={handleOffscreenClass(section)}
    on:resize={handleOffscreenClass(section)}
/>

<section class={"ranking " + scheme} style={(bg_img && bg_img.path) ? "background-image: url('"+'https://www.saintdust.com'+bg_img.path+"');" : ""} bind:this={section}>
{#if ranks && ranks.length}
    <ul class="content">
        {#each ranks as r}
            {#if r.value}
            <li>
                <h1 class="count">{@html (r.value.heading) ? r.value.heading : ""}</h1>
                <span class="name">{(r.value.name) ? r.value.name : ""}</span>
            </li>
            {/if}
        {/each}
    </ul>
{/if}
</section>

<style lang="scss" global>
    section.ranking {
        display: flex;
        flex-flow: row;
        width: 100%;
        padding: 2em;
        box-sizing: border-box;
        color: black;
        background-color: lightgray;
        background-position: center;
        background-size: cover;
        &.dark {
            color: white;
            background-color: black;
            h1 {
                -webkit-text-stroke-color: white;
                :global(b) {-webkit-text-fill-color: white;}
            }
        }
        &.offscreen .content {
            animation-name: fadeout;
            animation-duration: 0.75s;
            animation-fill-mode: forwards;
        }
        .content {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            justify-content: space-around;
            border: none;
            animation-name: scalein;
            animation-duration: 0.75s;
            li {
                border: none;
                padding: 1em;
                display: flex;
                flex-flow: column;
                text-transform: uppercase;
                align-items:center;
                .count {
                    font-size: 3em;
                    @media (max-width: 500px) {
                        font-size: 2.5em;
                    }
                }
            }
        }
    }
    body.theme-goldshow .ranking .content .count {
            font-size: 4em;
            @media (max-width: 500px) {
                font-size: 3.5em;
            }
    }
</style>