<script>
    import { onDestroy } from "svelte";
    import { acnt } from "../routes/AccentStore.svelte";
    export let changeTo = "";
    export let accentTo = "";

    let accent;
    let acntUnsub = acnt.subscribe(a => accent = a.current);

    if (changeTo && changeTo.length && !changeTo.includes(" ")){
        let cl = document.body.classList;
        for(let i=0;i<cl.length;i++) {
            if (cl[i].includes("theme-")) {
                cl.remove(cls);
            }
        }
        cl.add("theme-"+changeTo);
    }

    if (accentTo && accentTo.length && accentTo.includes("rgb")){
        if (accent !== accentTo) acnt.setColor(accentTo);
    } else {
        acnt.resetColor();
    }

    onDestroy(()=>{
        document.body.classList.remove("theme-"+changeTo);
        acntUnsub();
    })
</script>