<script context="module">
    import { writable } from "svelte/store";
    export const acnt = (()=>{
        const { subscribe, update } = writable({
            current: "",
            default: ""
        });
        return {
            subscribe,
            setColor: (color) => update(w => {
                w.current = color;
                return w;
            }),
            resetColor: () => update(w => {
                w.current = w.default;
                return w;
            }),
            setDefaultColor: (color) => update(w => {
                w.default = color;
                return w;
            })
        }
    })();
</script>