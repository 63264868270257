<script>
    import { onMount } from "svelte";
    import { YTurlToEmbed } from "../scripts/YTurlToEmbed.svelte";
    import { isOffScreen } from "../scripts/isOffScreen.svelte";
    // import { gdprStore } from "../routes/GDPRStore.svelte";
    import { lang } from "../routes/LanguageStore.svelte";

    export let ytlink = "";
    let ytembedlink;
    $: ytembedlink = YTurlToEmbed(ytlink);
    export let local = "";
    let videoHeight = recalculateVideoHeight();
    export let theme = "";
    export let loopopacity = "";
    export let preview = false;
    let classes;
    $: classes = handleVideoClasses(theme);
    
    // let gdprConsent;
    // let unsubGdpr = gdprStore.subscribe((g) => (gdprConsent = g.consent));
    // let gdprException = false;


    let language;
    let unsubLang = lang.subscribe((l) => (language = l.current));

    function handleVideoClasses(theme) {
        var classes = [];
        if (theme) {
            if (theme.includes("fullwidth")) {
                classes.push("span");
            }
            if (theme.includes("embedded")) {
                classes.push("embedded");
            }
            if (theme.includes("iPhone immersive")) {
                classes.push("immersive_screen");
            }
            if (theme.includes("autoplay")) {
                classes.push("autoplay");
            }
        }
        return (classes.length ? " " + classes.join(" ") : "");
    }

    function recalculateVideoHeight() {
        var assumedVideoWidth = (window.innerWidth < 1200) ? window.innerWidth : 1200;
        return (assumedVideoWidth / 16 * 9) + "px";
    }

    function enableInPreview(e) {
        // var lastScrollPos = e.target.parentElement.getBoundingClientRect().top + window.pageYOffset;
        var lastScrollPos = window.pageYOffset;
        preview = false;
        setTimeout(()=>{
            window.scrollTo({top:lastScrollPos});
        },20);
    }
    function allowGDPRonce(e) {
        gdprException = true;
    }

    function handleOffscreenVid(loopVid) {
        isOffScreen(loopVid,(v)=>{
            v.pause();
            v.classList.add("offscreen");
        },(v)=>{
            if (v.paused) {
                v.play();
                v.classList.remove("offscreen");
            }
        },3);
    }

    // function fixLocalVideoPath(path) {
    //     return (!path.includes("/cockpit/storage/uploads/")) ? "/cockpit/storage/uploads/" + path : path;
    // }

    let loopVid;
    onMount(()=>{
        handleOffscreenVid(loopVid);
        return () => {
            // unsubGdpr();
            unsubLang();
        }
    });
</script>

<svelte:window 
    on:resize={()=>{videoHeight = recalculateVideoHeight(); handleOffscreenVid(loopVid);}}
    on:scroll={handleOffscreenVid(loopVid)}
/>

<section class={"video" + classes}>
{#if ytembedlink !== ""}
    {#if preview}
    <div class="preview" style={"height: " + videoHeight}>
        <span class="message warn">Video naschvál nie je zobrazené kvôli častému refreshovaniu</span>
        <a class="button" href="?" on:click={enableInPreview}>Načítať na skúšku</a>
    </div>
    <!-- {:else if gdprConsent || gdprException} -->
    {:else}
    <iframe
        class="ytvideo"
        title="YouTube Video"
        src={ytembedlink} 
        frameborder="0" 
        allowfullscreen
        style={"height: " + videoHeight}
    ></iframe>
    <!-- {:else}
    <div class="gdpr-notice" style={"height: " + videoHeight}>
        {#if language == "sk"}
        <span class="message warn">GDPR Varovanie: YouTube môže využívať cookies a zbierať údaje o Vašej návštevnosti. 
            Aktualizujte svoje GDPR preferencie na spodku tejto stránky ak toto varovanie už nechcete vidieť.</span>
        <a class="button" href="?" on:click={allowGDPRonce}>Povoliť jednorázovo</a>
        {:else}
        <span class="message warn">GDPR Warning: YouTube may track your behavior. 
            Update your GDPR preferences at the bottom of this site to not see this warning.</span>
        <a class="button" href="?" on:click={allowGDPRonce}>Allow just once</a>
        {/if}
    </div> -->
    {/if}
<!-- allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  -->
{:else if local}
    {#if classes.includes("autoplay") && !preview}
        <video  src={'https://www.saintdust.com' + local.path} 
                style={"max-height: " + videoHeight + ";" + (loopopacity ? "opacity: " + (parseInt(loopopacity)/100) + ";" : "")} 
                muted loop autoplay playsinline bind:this={loopVid}></video>
    {:else if !preview}
        <!-- svelte-ignore a11y-media-has-caption -->
        <video src={'https://www.saintdust.com' + local.path} style={"max-height: " + videoHeight} controls></video>
    {:else}
        <div class="preview" style={"height: " + videoHeight}>
            <span class="message warn">Video naschvál nie je zobrazené kvôli častému refreshovaniu</span>
            <a class="button" href="?" on:click={enableInPreview}>Načítať na skúšku</a>
        </div>
    {/if}
{/if}
</section>

<style lang="scss" global>
    section.video {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        // height: 100%; this apparently makes the video disappear on iOS 10 for no reason
        overflow: hidden;
        video, .ytvideo, .preview {
            position: relative;
            width: 100%;
            // height: 100%;
            height: 55vh;
            max-width: 1200px;
            background: transparent;
        }
        video {
            animation-name: semifadein;
            animation-duration: 0.75s;
        }
        video.offscreen {
            // opacity: 0.5;
            animation-name: semifadeout;
            animation-duration: 0.75s;
            animation-fill-mode: forwards;
        }
        .preview, .gdpr-notice {
            padding: 8% 6% 8% 10%;
            box-sizing: border-box;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            background-color: rgb(75, 75, 75);
            span {flex-grow: 0;}
            @media (max-width: 600px) {
                font-size: 12px;
            }
            @media (max-width) {
                .message {display: none; }
            }
        }
        &.span {
            video, .ytvideo {
                left: 0;
                right: 0;
                object-fit: cover;
                max-width: none;
            }
        }
        &.embedded {
            height: 100%;
            video {
                animation: none;
                height: 100%;
                max-height: 100% !important;
            }
            video.offscreen {
                animation: none;
            }
        }
        &.immersive_screen {
            max-width: 1200px;
            video, .ytvideo {
                overflow: hidden;
                // padding: 4.5em 4em;
                padding: 8% 6%;
                box-sizing: border-box;
                background: black;
            }
            &:before {
                content: "";
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 99;
                pointer-events: none;
                background: url("/img/video/immersive_screen.svg") repeat center !important;
            }
        }
    }
    body.theme-goldshow .video.immersive_screen:before {
        background: url("/img/video/immersive_screen_dark.svg") repeat center !important;
    }
</style>