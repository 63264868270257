<script context="module">
    let previewPageScrollY = 0;
    // Assign new scroll value if it isn't 0
    export function beforePreviewUpdate() {
        // console.log("beforeupdate", window.pageYOffset);
        if (window.pageYOffset !== 0) {
            previewPageScrollY = window.pageYOffset;
            // console.log("set",previewPageScrollY);
        }
    }
    // Scroll back to where user was before reload
    export function afterPreviewUpdate() {
        if (window.pageYOffset === 0) {
            window.scrollTo({top:previewPageScrollY});
            // console.log("scroll",previewPageScrollY);
        }
    }
</script>

<script>
    export let receivedMessage = false;

    // Receive messages from Cockpit
    window.addEventListener("message",function(e){
        if (e.isTrusted && 
            e.data.event === "cockpit:collections.preview" &&
            ["http://localhost","https://strukshow.saintdust.com","https://brianstruk.saintdust.com","https://dev.saintdust.com","https://lubastrukova.saintdust.com","https://saintdust.com"].includes(e.origin)) {
            receivedMessage = {loading:true};
            setTimeout(()=>{
                receivedMessage = e.data.entry;
                switch(e.data.lang) {
                    case "en": receivedMessage.layout = receivedMessage.layout_en; break;
                    default: break;
                }
            },10)
        } else { console.log("[MESSAGE NOT TRUSTED]", e, e.isTrusted, e.data.event, e.origin) }
    });
    // If no connection, redirect to homepage after 2sec
    let redirect = setTimeout(function(){
        if (receivedMessage) {
            clearTimeout(redirect);
        } else {
            window.location.replace("/");
        }
    },2000);
</script>