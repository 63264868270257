<script>
    import { onDestroy } from "svelte";
    import { langSlugDetect, removeLangFromSlug, getLangFromSlug } from "../scripts/LangDetectModule.svelte";
    import { route } from "./RouterModule.svelte";
    import { lang } from "./LanguageStore.svelte";

    export let beforeReroute = function(){};
    export let afterReroute = function(){};

    export let fullslug; // Includes language & actual slug
    export let excludedSlugs = []; // Not included in language detection/rerouting
    export let excludedPrefixSlugs = []; // Not included in language detection/rerouting

    // Initial language detection and rerouting
    let currentLang;
    let supportedLangs;
    let langUnsub = lang.subscribe(l => {
        supportedLangs = l.supported;
        currentLang = l.current;
    });

    // Boolean to check whether default language exists and preventHistory if it doesn't
    // Example: init. strukshow.com/show -> strukshow.com/en/show won't cause a back button
    let initialRedirect = false;
    if (!currentLang) { initialRedirect = 2; }

    // Check reactively if any excludedPrefixSlugs entries match current fullslug partially, excludedSlugs fully
    let localizable = !isExcludedPrefixSlug(fullslug) && !isExcludedSlug(fullslug);
    $: localizable = !isExcludedPrefixSlug(fullslug) && !isExcludedSlug(fullslug);
    function isExcludedPrefixSlug(slug) {
        return !!excludedPrefixSlugs.map(entry => fullslug.match(entry)).filter(entry => ![null,undefined].includes(entry)).length;
    }
    function isExcludedSlug(slug) {
        return !!excludedSlugs.includes(fullslug);
    }

    // Initial language detection/setting
    [currentLang] = langSlugDetect(supportedLangs, fullslug);
    lang.change(currentLang);

    // Route / document changes on the change of current language
    $: fixSlugOnLangChange(currentLang,initialRedirect);
    $: document.documentElement.setAttribute("lang",currentLang);
    function fixSlugOnLangChange(newlang,preventHistory=0){
        if (localizable) {
            fullslug = route(fullslug, "/" + newlang + removeLangFromSlug(supportedLangs,fullslug), preventHistory);
        }
        if (initialRedirect) initialRedirect = false;
    }

    // CSS: Close anything open, used during route
    function removeOpenClasses() {
        var open = document.getElementsByClassName("open");
        for (var i in open) {
            if (open[i].classList) {
                open[i].classList.remove("open");
            }
        }
    }

    // History router support
    window.addEventListener("popstate",function(e){
        beforeReroute("BACK");
        // First check whether we're navigating back to a different language
        // This will in such case perform given navigation and change language in one update
        // because "fixSlugOnLangChange" listens to this writable
        lang.change(getLangFromSlug(supportedLangs, window.location.pathname));
        // If the language is the same, in other words pathname differs, route will do the navigating
        // If the pathname already got changed in previous command, route's first condition will stop
        // a second unnecessary change from occuring
        fullslug = route(fullslug, window.location.pathname,1);
        afterReroute("BACK");
    });

    // Click delegation
    window.addEventListener("click",function(e){
        if (e.target) {
            // Detect if an anchor has been pressed
            var link = (e.target.tagName == "A") ? e.target : (e.target.parentElement && e.target.parentElement.tagName == "A") ? e.target.parentElement : false;
            // Auto-reroute same-host non-modal links
            if (link && link.hostname == window.location.hostname && link.getAttribute("modal") !== "true") {
                e.preventDefault();
                beforeReroute(link);
                if (link.pathname.charAt(0) == "/") {
                    fullslug = route(fullslug, link.pathname);
                } else {
                    fullslug = route(fullslug, fullslug + "/" + link.pathname);
                }
                removeOpenClasses();
                afterReroute(link);
            }
        }
    });



    onDestroy(langUnsub);
</script>