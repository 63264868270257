<script>
    export let layout = [];
    export let preview = false;

    import Titulka from "../sections/Titulka.svelte";
    // import ScrollVideo from "../sections/ScrollVideo.svelte";
    import Ranking from "../sections/Ranking.svelte";
    import Ref from "../sections/Ref.svelte";
    import Slide from "../sections/Slide.svelte";
    import Video from "../sections/Video.svelte";
    import Bar from "../sections/Bar.svelte";
    import Floaters from "../sections/Floaters.svelte";
    import Flies from "../sections/Flies.svelte";
    import Bubbles from "../sections/Bubbles.svelte";

    // Dev
    function show(object) {
        return JSON.stringify(object,undefined,2);
    }
</script>

{#if layout && layout.length}
{#each layout as l}

    <!-- Component switch asignment loop -->
    {#if l.component === "titulka"}
        <Titulka {...l.settings} />
    <!-- {:else if l.component === "scrollvideo"} -->
        <!-- <ScrollVideo {...l.settings} /> -->
    {:else if l.component === "ranking"}
        <Ranking {...l.settings} />
    {:else if l.component === "bubbles"}
        <Bubbles {...l.settings} />
    {:else if l.component === "ref"}
        <Ref {...l.settings} {preview} />
    {:else if l.component === "slide"}
        <Slide {...l.settings} />
    {:else if l.component === "video"}
        <Video {...l.settings} {preview} />
    {:else if l.component === "bar"}
        <Bar {...l.settings} />
    {:else if l.component === "floaters"}
        <Floaters {...l.settings} />
    {:else if l.component === "flies"}
        <Flies {...l.settings} {preview} />
    <!-- {:else} -->
        <!-- <span class="message error">Komponent nedefinovaný</span> -->
    {/if}

    <!-- Debug -->
    <!-- <details class="button">
        <summary style="font-weight:bold;">{l.component}</summary>
        <pre>{show(l.settings)}</pre>
    </details> -->
{/each}
{:else}
<section class="">
    <span class="message warn">Page is empty / Stránka je prázdna</span>
</section>
{/if}