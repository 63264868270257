<script context="module">
    // Initializes and launches the slideshow
    // Make sure that msStayOnFor value is not lower than msAnimateOut

    // Returns a destroy function that stops the slideshow
    // slidesElms: Array of elements within the slideshow
    // msStayOnFor: How long a slide will show for (incl. animateIn)
    // msAnimateOut: How long will it take for out-animation to happen (after this next element will come right in)

    // CSS classes:
    // hidden: Should simple be display: none;
    // animate-in: Ideal for a CSS transition to take place
    // animate-out: ^
    // Element will stay on "animate-in" until it switches to "animate-out", there is no "steady" class
    export function animate(slidesElms, current=0, backwards=false, instant=false, callback=(current)=>{} ) {
        if (current === undefined) return;
        if (slidesElms && slidesElms[current] && slidesElms.length >= 2) {
            let classes = slidesElms[current].classList;
            // Finish animation
            classes.remove("visible");
            classes.remove("animate-in");
            if (!instant) {classes.add("animate-out");}
            setTimeout(()=>{
                // Hide finished animation
                classes.add("hidden");
                classes.remove("animate-out");
                // Move to next motto
                current = (backwards ? ((current <= 0) ? slidesElms.length - 1 : --current) : ((current >= slidesElms.length - 1) ? 0 : ++current));
                classes = slidesElms[current].classList;
                // Start animation
                classes.add(instant ? "visible" : "animate-in");
                classes.remove("hidden");
                callback(current);
            },instant ? 0 : 1000);
        }
    }

    export function startSlideshow(slidesElms,current=undefined,msStayOnFor=10000) { 
        let mottoInterval;
        function getCurrent() {return current;}
        function setCurrent(next) {current = next;}
        function stopSlideshow() {if (mottoInterval) clearInterval(mottoInterval);}
        // Initialization
        if (slidesElms.length > 1) {
            // Animation
            current = current || 0;
            animate(slidesElms,current,false,false,setCurrent);
            mottoInterval = setInterval(()=>{animate(slidesElms,current,false,false,setCurrent)},msStayOnFor);
        } else if (slidesElms.length === 1) { 
            // No animation, because only one motto exists
            current = undefined;
            slidesElms[0].classList.remove("hidden");
        } else {
            // No mottos exist
            current = undefined;
        }
        return [getCurrent,stopSlideshow];
    }
</script>