<script>
    import { onDestroy } from "svelte";
    import { modal } from "../../parts/Modal.svelte";
    import { lang } from "../../routes/LanguageStore.svelte";
    import { acnt } from "../../routes/AccentStore.svelte";
    export let nav = [];

    let language;
    let accent;
    let langUnsub = lang.subscribe(l => language = l.current);
    let acntUnsub = acnt.subscribe(a => accent = a.current);

    function clickHandler(e,lity,href) {
        var m;
        if (lity) {
            e.preventDefault();
            modal.activate(href);
        } else if (m = href.match(/^nextSection\((\d*?)\);?$/)) {
            // /^javascript:nextSection\((\d*?)(?:,(false|true|0|1))?\);?$/ for a digit comma boolean
            e.preventDefault();
            try{window.nextSection(e.target.getBoundingClientRect().bottom + window.pageYOffset, m[1] ? parseInt(m[1]) : 1);}catch{}
        };
    }

    function prependLang(href) {
        return (href.charAt(0) === "/") ? (language ? "/" + language + href : href) : href;
    }

    onDestroy(()=>{langUnsub(); acntUnsub();});
</script>

<nav>
{#each nav as link}
{#if link.value}
{#if link.value.href}
    <a 
        class={(link.value.button) ? "button" : ""} 
        href={(link.value.href && !link.value.href.match(/^nextSection\((\d*?)\);?$/)) ? prependLang(link.value.href) : "#"} 
        on:click={(e)=>{clickHandler(e, link.value.lity, link.value.href)}}
        modal={(link.value.lity || (link.value.href && link.value.href.match(/^nextSection\((\d*?)\);?$/))) ? true : false}
        style={((link.value.button) ? "background: " : "color: ") + accent + ";"}
        rel="noopener"
        >
        <!-- FontAwesome icon + Link name (not on separate lines because whitespace) -->
        {#if link.value.icon}<i class={link.value.icon}></i>{/if}{(link.value.name) ? link.value.name : ""}
    </a>
    {:else}
    <!-- svelte-ignore a11y-missing-attribute -->
    <a 
        class={"disabled" + (link.value.button ? " button" : "")}
        style={((link.value.button) ? "background: " : "color: ") + accent + ";"}
        disabled
    >
        <!-- FontAwesome icon + Link name (not on separate lines because whitespace) -->
        {#if link.value.icon}<i class={link.value.icon}></i>{/if}{(link.value.name) ? link.value.name : ""}
    </a>
{/if}
{/if}
{/each}
</nav>

<style lang="scss" global>
    nav {
        display: flex;
        flex-flow: row;
        align-items: center;
        a {
            padding: 10px;
            margin: 5px;
        }
        a:not(.button):not(:first-of-type) {margin-left: 10px;}
        @media (max-width: 700px) {
            width: 100%;
            flex-flow: column;
            a:not(.button):not(:first-of-type) {margin-left: 5px;}
        }
        svg {
            pointer-events: none;
        }
    }
</style>