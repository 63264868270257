<script>
    import { onMount, onDestroy } from 'svelte';
    import LangSelector from "./LangSelector.svelte";
    import { startSlideshow } from "../scripts/Slideshow.svelte";
    import { lang } from "../routes/LanguageStore.svelte";
    import { acnt } from "../routes/AccentStore.svelte";

    export let logo = "";
    export let navSocial = [];
    export let navPages = []; // name, slug, showAs: button
    export let filterNav = "";
    export let hideNav = false;
    export let mottosOnHideNav = [];
    export let addMargin = true;
    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    export let showArrowNoLogo = true;
    export let hideLangSelector = false;

    // Language needed for hrefs
    let language;
    let langUnsub = lang.subscribe(l => language = l.current);

    // Accent for hrefs
    let accent;
    let acntUnsub = acnt.subscribe(a => accent = a.current);

    function rootSlug(slug,language,doNotLocalize) {
        return (language && !doNotLocalize ? "/" + language : "") + (slug.charAt(0) === "/" ? slug : "/" + slug);
    }

    function anchorClasses(page) {
        var classes = [];
        if (page.showAs && page.showAs === "button") classes.push("button");
        if (window.location.pathname === rootSlug(page.slug,language)) classes.push("active");
        return classes.join(" ");
    }

    let isSafari = (()=>{
        let ua = window.navigator.userAgent;
        return (ua.includes("Safari") && (ua.includes("iPhone") || ua.includes("iPad") || ua.includes("Macintosh")) && !ua.includes("Chrome"))
    })();

    // Mobile nav toggle (note: bind:this only works onMount)
    // + Motto slideshow
    let mobileNav;
    let toggleNav = ()=>{};
    let motto;
    let stopSlideshow = ()=>{};
    let slideshowGetCurrent = ()=>{return undefined};
    onMount(()=>{
        toggleNav = ()=>{
            var cl = mobileNav.classList;
            cl.contains("open") ? cl.remove("open") : cl.add("open");
        }
        if (motto && motto.children) [slideshowGetCurrent, stopSlideshow] = startSlideshow(motto.children);
    })
    onDestroy(()=>{
        stopSlideshow();
        langUnsub();
        acntUnsub();
    })
</script>

<header class={scheme + (isSafari ? " safari" : "")}>
    <div class="content">

        <!-- Mobile Navigation Toggle -->
        {#if navPages.length}<button class="mobileNavToggle" on:click={toggleNav} aria-label="Toggle Mobile Navigation"><i class="fa fa-bars"></i></button>{/if}

        <!-- Logo -->
        <a class="logo" href={rootSlug("/",language)}>
            {#if logo}
                <img src={logo && "https://www.saintdust.com" + logo.path || "https://www.saintdust.com" + logo} alt="Logo">
            {:else}
                <span>{@html showArrowNoLogo ? "<i class='fa fa-arrow-left'></i>" : ""}StrukShow</span>
            {/if}
        </a>

        <!-- Central Part of Header -->
        {#if navPages.length}
            <!-- Page navigation -->
            <div class={"nav-wrapper" + (hideNav ? " hide_d" : "")} bind:this={mobileNav}>
                <nav class="pages">
                    {#each navPages as page}
                    {#if filterNav === "" || (page.nav && page.nav.display && page.nav.display.includes(filterNav) )}
                        <a class={anchorClasses(page)} href={rootSlug(page.slug,language,page.static)}>{page.name}</a>
                    {/if}
                    {/each}
                </nav>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="bg-close hide_d" on:click={toggleNav}></div>
            </div>
        {/if}
        {#if hideNav && mottosOnHideNav && mottosOnHideNav.length}
            <!-- Mottos -->
            <ul class="motto" bind:this={motto}>
                {#each mottosOnHideNav as motto}
                    {#if motto.value}<li class="hidden">{motto.value}</li>{/if}
                {/each}
            </ul>
        {/if}

        <!-- Right Part of Header -->
        {#if navSocial.length || !hideLangSelector}
        <div class="right">
            <!-- Social Navigation -->
            {#if navSocial.length}
            <nav class="social">
                {#each navSocial as social}
                    <a href={social.value} target="_blank" rel="noopener" style={"color: " + accent + ";"}><i class={"fab fa-" + social.field.name}></i></a>
                {/each}
            </nav>
            {/if}

            <!-- Language Selector -->
            {#if !hideLangSelector}<LangSelector />{/if}
        </div>
        {/if}
    </div>
</header>

{#if addMargin}
<div class={"header-margin-fix " + scheme}></div>
{/if}

<style lang="scss" global>
$color-link: #0083FF;
$height: 60px;
header, .header-margin-fix {
    height: $height;
    width: 100%;
    background:white;
    &.dark { background: black; }
}
header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    border-top: none;
    border-bottom: 1px solid lightgray;
    z-index: 999;
    &.safari {
        // only safari on iOS because it looks better this way there
        // lags & buggy on chrome over videos
        // NYI on firefox
        background: rgba(255, 255, 255, 0.75);
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        &.dark { background: rgba(0, 0, 0, 0.75); }
    }
    .right {
        display: flex;
        align-items: center;
    }
    &.dark {
        border-bottom: 1px solid rgb(66, 66, 66);
        nav a { color: white; }
    }
    .content {
        width: 100%;
        max-width: 1200px;
        height: 90%;
        justify-content: space-between;
        display: flex;
        align-items:center;
        flex-flow: row;
        position: relative;
        max-height: 100%;
        height:inherit;
        animation-name: slideindown;
        animation-duration: 0.75s;
    }
    a.logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: $height - 20px;
    }
    img {
        position: relative;
        box-sizing: border-box;
    }
    .motto {
        border: none;
        li { border: none; }
        .hidden { display: none; }
        .animate-in {
            @keyframes slidein {
                from { transform: translateX(-100%); opacity: 0;}
                to { transform: translateX(0%); opacity: 1;}
            }
            animation-name: slidein;
            animation-duration: 1s;
        }
        .animate-out {
            @keyframes slideout {
                from { transform: translateX(0%); opacity: 1;}
                to { transform: translateX(100%); opacity: 0;}
            }
            animation-name: slideout;
            animation-duration: 1s;
        }
        @media (max-width: 1200px) {
            display: none;
        }
    }
    nav {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100%;
        padding: 0 15px;
        align-items: center;
        a {
            position: relative;
            padding: 5px 15px;
            text-decoration: none;
            outline: none;
            text-transform: uppercase;
            &.active:not(.button) {font-weight:bold;}
            &:hover {text-decoration: underline;}
        }
        &.social {
            flex-flow: row;
            width: auto;
            a {
                margin: 0;
                color: $color-link;
            }
            svg {
                margin: 0;
            }
            @media (max-width: 550px) {
                display: none;
            }
        }
    }
    .mobileNavToggle {
        display: none;
        @media (max-width: 600px) {
            padding: 10px;
        }
    }
    @media (min-width: 1201px) {
        .hide_d {
            display: none;
        }
    }
    @media (max-width: 1200px) {
        flex-flow: row;
        .nav-wrapper {
            position:absolute;
            visibility: hidden;
            pointer-events: none;
            width: auto;
            height: 100vh;
            top: calc(100% + 10px);
            left: -30px;
            right: -30px;
            padding: 0;
            &.open {
                visibility: visible;
                pointer-events: all;
                .bg-close {
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter: blur(10px);
                }
            }
            .bg-close {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 997;
                background: rgba(94, 94, 94, 0.712);
            }
        }
        nav.pages {
            display: flex;
            position: absolute;
            top: 0;
            left: 30px;
            padding: 0;
            width: calc(100% - 60px);
            justify-content: flex-start;
            height:auto;
            flex-flow: column;
            border: 1px solid rgba(0, 0, 0, 0.2);
            background: white;
            z-index: 998;
            a {
                color: black;
                width: 100%;
                margin: 0 !important;
                text-align: center;
                padding: 1em 0;    
            }
            a:not(:last-of-type) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
        }
        &.dark nav.pages {
            border-color: rgba(255, 255, 255, 0.2);
            background: black;
            a { color: white; }
            a:not(:last-of-type) {
                border-color: rgba(255, 255, 255, 0.2);
            }
        }
        nav.social {
            a:not(.button):not(:first-of-type) {margin-left: 0;}
            a {padding: 5px 10px;}
        }
        .mobileNavToggle {
            display: flex;
            margin-right: 1em;
            border: 1px solid rgba(0, 0, 0, 0.2);
            color: $color-link;
            background: transparent;
            &:hover, &:focus {
                background: transparent;
            }
            &:focus {
                box-shadow: 0 0 0 2px inset rgba(0,0,0,0.2);
            }
        }
        &.dark .mobileNavToggle {
            border-color: rgba(255, 255, 255, 0.2);
            &:focus {
                box-shadow: 0 0 0 2px inset rgba(255,255,255,0.2);
            }
        }
    }
}
</style>