<script context="module">
    import { writable } from "svelte/store";
    // Unlike the internal states of GDPR partial / localStoarage, which can also be null/undefined, 
    // this store only offers simplified true/false values to be read by any other sections of the site.
    // GDPR.svelte should be the only writer of this store.
    export const gdprStore = (()=>{
        const { subscribe, update } = writable({
            consent: false
        });
        return {
            subscribe,
            userAllowConsent: () => update(w => {
                w.consent = true;
                return w;
            }),
            userDenyConsent: () => update(w => {
                w.consent = false;
                return w;
            })
        }
    })();
</script>