<script context="module">
    import { writable } from "svelte/store";
    export const lang = (()=>{
        const { subscribe, update } = writable({
            current: "",
            supported: []
        });
        return {
            subscribe,
            setSupported: (supportedArray) => update(w => {
                w.supported = supportedArray;
                return w;
            }),
            change: (lang) => update(w => {
                w.current = lang;
                return w;
            })
        }
    })();
</script>