<script>
    import { onDestroy } from "svelte";
    import { lang } from "../routes/LanguageStore.svelte";
    import { gdprStore } from "../routes/GDPRStore.svelte";

    export let consent = localStorage.getItem("GDPRconsent");
    export let scripts = [];
    export let providers = "";
    export let reset = false;
    let lastProviders = localStorage.getItem("GDPRproviders");

    let language;
    let unsubLang = lang.subscribe((l) => (language = l.current));

    if (consent == "true") {
        gdprStore.userAllowConsent();
    } else {
        gdprStore.userDenyConsent();
    }

    function providersChangeCheck() {
        if (providers !== lastProviders) {
            resetConsent();
            lastProviders = providers;
        }
    }
    function hasAnswered(consent) {
        providersChangeCheck();
        return consent === "true" || consent === "false";
    }
    function hasAllowedConsent(consent) {
        providersChangeCheck();
        return consent === "true";
    }
    function resetConsent() {
        consent = null;
        localStorage.removeItem("GDPRconsent");
        localStorage.removeItem("GDPRproviders");
        gdprStore.userDenyConsent();
    }
    function userAllowConsent() {
        consent = "true";
        localStorage.setItem("GDPRconsent", consent);
        localStorage.setItem("GDPRproviders", providers);
        gdprStore.userAllowConsent();
    }
    function userDenyConsent() {
        consent = "false";
        localStorage.setItem("GDPRconsent", consent);
        localStorage.setItem("GDPRproviders", providers);
        gdprStore.userDenyConsent();
    }
    function getValue(repeaterObject) {
        return repeaterObject.value;
    }

    $: reset = hasAnswered(consent) ? resetConsent : false;

    onDestroy(() => {
        unsubLang();
    });
</script>

<svelte:head>
    {#if hasAllowedConsent(consent)}
        {#each scripts as scr}
            {#if scr.value}
                <script src={getValue(scr)}></script>
            {/if}
        {/each}
    {/if}
</svelte:head>

{#if (scripts.length || providers.length) && !hasAnswered(consent)}
    {#if language == "sk"}
        <div id="gdpr-consent">
            <p>
                <b>GDPR Oznam:</b> Ak súhlasíte, táto webstránka bude za cieľom
                zlepšenia poskytovaných služieb používať cookies a zbierať údaje
                o Vašej návštevnosti
                {#if providers} od nasledovných poskytovateľov: {providers}.{:else}.{/if}
            </p>
            <ul>
                <li>
                    <button class="button-link" on:click={userDenyConsent}>Odmietnúť</button>
                </li>
                <li>
                    <button class="button" on:click={userAllowConsent}>Povoliť</button>
                </li>
            </ul>
        </div>
    {:else}
        <div id="gdpr-consent">
            <p>
                <b>GDPR Notice:</b> If you agree, this website will use cookies
                and your traffic data to ensure you get the best experience
                {#if providers} from the following providers: {providers}.{:else}.{/if}
            </p>
            <ul>
                <li>
                    <button class="button-link" on:click={userDenyConsent}>Decline</button>
                </li>
                <li>
                    <button class="button" on:click={userAllowConsent}>Accept</button>
                </li>
            </ul>
        </div>
    {/if}
{/if}

<style lang="scss" global>
    #gdpr-consent {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: white;
        z-index: 9999;
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        padding: 0 10px;
        box-sizing: border-box;
        p {
            color: black;
        }
        ul {
            display: flex;
            flex-flow: row;
            border: none;
            margin: 0;
            padding: 0;
            li {
                border: none;
            }
        }
        @media screen and (max-width: 600px) {
            flex-flow: column;
            align-items: center;
        }
    }
</style>
